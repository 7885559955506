import { Button, Input, Textarea } from "@nextui-org/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../App";
import { useNavigate } from "react-router-dom";
import { handleSearch } from "../utils/utilityFunctions";
import { cn } from "../utils/cn";
import upArrow from "../images/backward-arrow.svg";
import upArrowWhite from "../images/up-arrow-white.svg";
import recordIcon from "../images/record-icon.svg";
import attachmentIcon from "../images/attachment-icon.svg";
import { isMobile, isDesktop } from "react-device-detect";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { FaRegCircleStop } from "react-icons/fa6";

const ChatInput = ({ className }) => {
  const context = useContext(AppContext);
  const { enteredPrompt, setEnteredPrompt, chatResponse } = context;
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(true);
  const { transcript, listening, resetTranscript } = useSpeechRecognition();

  useEffect(() => {
    if (enteredPrompt) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [enteredPrompt]);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearch(context, navigate);
    }
  };

  const [lastTranscript, setLastTranscript] = useState("");
  const [interimTranscript, setInterimTranscript] = useState("");

  // useEffect(() => {
  //   if (transcript !== lastTranscript) {
  //     setEnteredPrompt((prev) => prev + transcript.replace(lastTranscript, ""));
  //     setLastTranscript(transcript);
  //   }
  // }, [transcript]);

  useEffect(() => {
    if (transcript !== lastTranscript) {
      setInterimTranscript(transcript.replace(lastTranscript, ""));
    }
  }, [transcript]);

  useEffect(() => {
    if (!listening) {
      setEnteredPrompt((prev) => prev + interimTranscript);
      setLastTranscript(transcript);
      setInterimTranscript("");
    }
  }, [listening]);

  const handleChange = (e) => {
    setInterimTranscript("");
    SpeechRecognition.stopListening();
    setEnteredPrompt(e.target.value);
  };

  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    return null;
  }

  return (
    <div className={cn("flex flex-col bg-[#F8F8F8]", className)}>
      {isMobile && (
        <Textarea
          tabIndex={-1}
          id="mainChatMobile"
          value={enteredPrompt + interimTranscript}
          onKeyDown={handleKeyPress}
          onChange={(e) => setEnteredPrompt(e.target.value)}
          className="w-full"
          minRows={2}
          classNames={{
            inputWrapper: ["bg-white", "shadow-lg", "p-[5px]"],
            innerWrapper: ["flex", "flex-col", "items-end"],
            input: [
              "font-bold",
              "placeholder:text-placeholder",
              "placeholder:text-[16px]",
              "placeholder:md:text-[18px]",
              "placeholder:leading-[26px]",
              "redHatMedium",
              "text-[16px]",
              "md:text-[20px]",
              "leading-[26px]",
              "pl-[10px]",
              "cursorStyles",
            ],
          }}
          placeholder={`${
            chatResponse.length > 0
              ? "Looking for more answers? Ask here…"
              : "How can i help you today? Ask here…"
          }`}
          // startContent={<span className="text-green-400 text-2xl pb-1">|</span>}
          endContent={
            <span className="flex gap-1 md:gap-5 w-full border border-t-[#71717160] border-r-0 border-l-0 border-b-0 justify-end pt-[5px]">
              {listening ? (
                <Button
                  className="min-w-max bg-transparent p-0"
                  onClick={SpeechRecognition.stopListening}
                >
                  <FaRegCircleStop size="25px" color="grey" />
                </Button>
              ) : (
                <Button
                  className="min-w-max bg-transparent p-1 pb-2"
                  onClick={() =>
                    SpeechRecognition.startListening({ continuous: true })
                  }
                >
                  <img
                    src={recordIcon}
                    alt="search arrow"
                    className=" w-[25px] h-[25px]"
                  />
                </Button>
              )}
              {/* <Button
                isDisabled
                className={`min-w-max bg-transparent p-0 ${
                  isMobile ? "p-0 w-[30px] h-[30px]" : ""
                }`}
              >
                <img src={attachmentIcon} alt="search arrow" className="" />
              </Button> */}
              <Button
                disabled={isDisabled}
                onClick={() => handleSearch(context, navigate)}
                className={`min-w-max rounded-[11px] ${
                  isDisabled
                    ? "bg-[#EBEBEB] cursor-default"
                    : "bg-[#000000] cursor-pointer"
                } ${isMobile ? "p-0 w-[30px] h-[30px]" : ""}`}
              >
                <img
                  src={isDisabled ? upArrow : upArrowWhite}
                  alt="search arrow"
                  className={`${isMobile ? "w-[15px] h-[15px]" : ""}`}
                />
              </Button>
            </span>
          }
        />
      )}
      {isDesktop && (
        <Input
          id="mainChatDesktop"
          value={enteredPrompt + interimTranscript}
          onKeyDown={handleKeyPress}
          onChange={handleChange}
          className="w-full"
          classNames={{
            inputWrapper: ["bg-white", "shadow-lg", "pr-[10px]", "h-[60px]"],
            input: [
              "font-bold",
              "placeholder:text-placeholder",
              "placeholder:text-[18px]",
              "placeholder:leading-[26px]",
              "redHatMedium",
              "text-[20px]",
              "leading-[26px]",
              "pl-[10px]",
              "cursorStyles",
            ],
          }}
          placeholder={`${
            chatResponse.length > 0
              ? "Looking for more answers? Ask here…"
              : "How can i help you today? Ask here…"
          }`}
          // startContent={<span className="text-green-400 text-2xl pb-1">|</span>}
          endContent={
            <span className="flex gap-5 cursor-pointer">
              {listening ? (
                <Button
                  className="min-w-max bg-transparent p-0"
                  onClick={SpeechRecognition.stopListening}
                >
                  <FaRegCircleStop size="25px" color="grey" />
                </Button>
              ) : (
                <Button
                  className="min-w-max bg-transparent p-0"
                  onClick={() =>
                    SpeechRecognition.startListening({ continuous: true })
                  }
                >
                  <img src={recordIcon} alt="search arrow" />
                </Button>
              )}

              {/* <Button isDisabled className="min-w-max bg-transparent p-0">
                <img src={attachmentIcon} alt="search arrow" />
              </Button>  */}
              <Button
                disabled={isDisabled}
                onClick={() => handleSearch(context, navigate)}
                className={`min-w-max rounded-[11px] ${
                  isDisabled
                    ? "bg-[#EBEBEB] cursor-default"
                    : "bg-[#000000] cursor-pointer"
                } `}
              >
                <img
                  src={isDisabled ? upArrow : upArrowWhite}
                  alt="search arrow"
                />
              </Button>
            </span>
          }
        />
      )}
      <p className="text-center w-full md:w-[100%] text-[10px] md:text-[12px] mt-3">
        <em>
          Prach AI Chatbot is designed to offer Support and Information. Our
          conversations do not replace Medical Advice
        </em>
      </p>
    </div>
  );
};

export default ChatInput;
