import { Button } from "@nextui-org/react";
import React from "react";
import { FiFilter } from "react-icons/fi";
import { cn } from "../utils/cn";

const FilterButton = ({ worksheetPagesData, handleFilter, ...props }) => {
  const handleApplyFilter = async () => {
    let isMobile = await handleFilter();
    if (isMobile) {
      props?.onClose?.();
    }
  };
  return (
    <div
      className={cn(
        "mt-0 sm:mt-3 flex  justify-between items-center",
        props.className
      )}
    >
      <span className="hidden sm:block">
        {worksheetPagesData?.total_items} results
      </span>
      <Button
        className="font-bold bg-blue-200 border-1 border-blue-500"
        startContent={<FiFilter />}
        onClick={handleApplyFilter}
        isLoading={props?.loading}
      >
        Apply Filter
      </Button>
    </div>
  );
};

export default FilterButton;
