import { Button, useDisclosure } from "@nextui-org/react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { IoMdArrowRoundBack } from "react-icons/io";
import { TbReportSearch } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../App";
import CustomCard from "./CustomCard";
import CustomDropdown from "./CustomDropdown";
import Loader from "./Loader";
import worksheetServices from "./services";
import WsModal from "./WsModal";

const Index = () => {
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const navigate = useNavigate();

  const context = useContext(AppContext);
  const {
    fetchedCategories,
    setFetchedCategories,
    fetchResources,
    setFetchResources,
    worksheetPagesData,
    setWorksheetPagesData,
  } = context;
  const getCategories = useCallback(async () => {
    try {
      const response = await worksheetServices.getAllCategories();
      setFetchedCategories(response);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  }, []);

  const getResources = useCallback(async (_page = 1, perPage = 10) => {
    try {
      const {
        items,
        page,
        per_page,
        total_items,
        total_pages,
      } = await worksheetServices.getAllResources(_page, perPage);
      setFetchResources(items || []);
      setWorksheetPagesData({
        page,
        per_page,
        total_items,
        total_pages,
      });
    } catch (error) {
      console.error("Error fetching resources:", error);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (fetchedCategories.length < 1 && fetchResources.length < 1) {
          await Promise.all([getCategories(), getResources()]);
        }
      } catch (error) {
        console.error("Error in fetchData:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className={"m-5 mb-20 lg:m-20 lg:mt-5"}>
          <Button
            className="border border-solid border-black mb-1"
            variant="bordered"
            size="sm"
            onClick={() => navigate("/")}
          >
            <IoMdArrowRoundBack /> Home
          </Button>
          <div className="flex sm:hidden justify-between items-center">
            <span>{worksheetPagesData?.total_items} results</span>
            <Button
              className="font-bold bg-blue-200 border-1 border-blue-500"
              onPress={onOpen}
              startContent={<TbReportSearch />}
            >
              Search Worksheets
            </Button>
          </div>
          <CustomDropdown setLoading={setLoading} className="hidden sm:block" />
          <CustomCard />
          <WsModal isOpen={isOpen} onOpenChange={onOpenChange}>
            <CustomDropdown setLoading={setLoading} />
          </WsModal>
        </div>
      )}
    </>
  );
};

export default Index;
