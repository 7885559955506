import React from "react";
import { Card, CardBody } from "@nextui-org/react";
import { cn } from "../utils/cn";
import YouTube from 'react-youtube';

const YoutubeContent = ({ id, videoLinkId, setSliderOn }) => {

const opts = {
    width: '418',
    height: '250',
    playerVars: {
        autoplay: 0,
    },
};
  return (
    <button className={cn("flex-1")}>
      <Card className="h-full flex-row min-h-[80px] cursor-pointer w-[420px] rounded-[10px] border border-[#DDDDDD]">
        <CardBody className="flex flex-row items-center justify-between gap-3 h-full p-0">
          <span className="flex items-center gap-2">
            <span className="flex flex-col gap-0">
              <span className="redHatMedim font-semibold text-[14px] leading-[21px] text-[#505050]">
              <YouTube videoId={videoLinkId} opts={opts} onPlay={() => {
                setSliderOn(false)
              }}
              onPause={() => {
                setSliderOn(true)
              }
              }
              onEnd={() => {
                setSliderOn(true)
              }
              } />
              </span>
            </span>
          </span>
        </CardBody>
      </Card>
    </button>
  );
};

export default YoutubeContent;
