import React, { useEffect, useState } from "react";
import { cn } from "../utils/cn";
import useScreenSize from "../utils/useScreenSize";
import emailIcon from "../images/email-48.png";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
} from "@nextui-org/react";

const Footer = ({ className }) => {
  const { screenWidth } = useScreenSize();
  const navigate = useNavigate();
  const footerItems = [
    {
      key: "prach-community",
      data: "Prach Community",
      link: "/prach-community",
    },
    // { key: "case-studies", data: "Case Studies" },
    // { key: "the-doctors", data: "The Doctors" },
    // { key: "the-advisors", data: "The Advisors" },
    // { key: "24/7-prach-assistance", data: "24/7 Prach Assistance" },
    { key: "about-prach", data: "About Prach", link: "/about-prach" },
    { key: "contact-prach", data: "Contact Us", link: "/contact-prach" },
    { key: "disclaimer", data: "Disclaimer", link: "/disclaimer" },
    { key: "privacy-policy", data: "Privacy Policy", link: "/privacy-policy" },
    // { key: "settings", data: "settings" },
    // { key: "need-help?", data: "Need Help?" },
    // { key: "usage-analytics", data: "usage analytics" },
  ];
  const [footerItemsLimit, setFooterItemsLimit] = useState(0);
  useEffect(
    () => {
      if (screenWidth >= 1200) {
        setFooterItemsLimit(footerItems.length);
      } else if (screenWidth < 1200 && screenWidth >= 1024) {
        setFooterItemsLimit(3);
      } else if (screenWidth < 1024 && screenWidth >= 600) {
        setFooterItemsLimit(3);
      } else if (screenWidth < 600 && screenWidth >= 400) {
        setFooterItemsLimit(2);
      } else if (screenWidth < 400) {
        setFooterItemsLimit(1);
      }
    },
    [footerItems.length, screenWidth]
  );

  return (
    <footer
      id="footer"
      className={cn(
        "text-[12px] sm:text-[14px] leading-[8px] flex justify-between px-20  text-[#4A4A4A] redHatMedium font-semibold",
        className
      )}
    >
      <div className="flex items-center justify-center">&copy; Prach, 2024</div>
      {!isMobile && (
        <div className="flex items-center justify-center">
          Love Prach? ❤️ Share it with other parents
        </div>
      )}
      <div className="flex gap-2 items-center justify-center">
        {/* <img
          src={emailIcon}
          alt="prach logo"
          className="h-[20px] w-[20px] pt-[2px]"
        />
        <b>prachaibot@gmail.com</b> */}
        <div className="flex gap-5">
          {footerItems.slice(0, footerItemsLimit).map((item) => {
            return (
              <span
                key={item.key}
                className="cursor-pointer"
                onClick={() => navigate(item.link)}
              >
                {item.data}
              </span>
            );
          })}
        </div>
        {footerItemsLimit !== footerItems.length && (
          <Dropdown>
            <DropdownTrigger>
              <span className="rotate-90 cursor-pointer">
                <svg
                  viewBox="0 0 24 24"
                  width="18"
                  height="18"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <circle cx="12" cy="12" r="1" />
                  <circle cx="12" cy="5" r="1" />
                  <circle cx="12" cy="19" r="1" />
                </svg>
              </span>
            </DropdownTrigger>
            <DropdownMenu
              aria-label="Dynamic Actions"
              items={footerItems.slice(footerItemsLimit)}
            >
              {/* {footerItems.map((item) => {
                return (
                  <DropdownItem
                    key={item.key}
                    onClick={() => navigate(item.link)}
                  >
                    {item.data}
                  </DropdownItem>
                );
              })} */}
              {(item) => {
                return (
                  <DropdownItem
                    key={item.key}
                    onClick={() => navigate(item.link)}
                  >
                    {item.data}
                  </DropdownItem>
                );
              }}
            </DropdownMenu>
          </Dropdown>
        )}
      </div>
    </footer>
  );
};

export default Footer;
