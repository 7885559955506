import React from 'react'
import { Adsense } from '@ctrl/react-adsense';
import '../css/AdsenseStylesheet.css';

function AdsenseExample() {
        //console.log("Ads Shown")
    return (
        <Adsense
            className='ExampleAdSlot'
            client="ca-pub-8454654764912158"
            slot="8454654764912158"
            adTest='on' //Dev Only
        />
    );

}

export default AdsenseExample;