import axios from "axios";
import { v4 as uuidv4 } from "uuid";

export const handleSearch = async (ctx, navigate, prompt = "") => {
  const {
    chatResponse,
    setChatResponse,
    enteredPrompt,
    setEnteredPrompt,
    setLoading,
    setCloseModal,
    setCheckLogin,
    signerData,
    chatHistory,
    setChatHistory,
    setQuestionPrompt,
    setIsOpenChatAccordion,
  } = ctx;
  if (false) {
    // setCheckLogin(true);
  } else {
    try {
      setLoading(true);
      let questionPromptTemp = "";
      if (prompt) {
        questionPromptTemp = prompt;
      } else if (enteredPrompt) {
        questionPromptTemp = enteredPrompt;
      }
      setQuestionPrompt(questionPromptTemp);
      navigate("/results");
      const response = await axios.post(
        "https://www.pannalabs.ai:5001/handle_user_prompt",
        {
          prompt: questionPromptTemp,
          rejectUnauthorized: false,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      let result = response.data.result.choices[0].message.content;
      if (result) {
        if (JSON.parse(localStorage.getItem("prachQuesCount")) >= 0) {
          setCheckLogin(true);
        }
        let count = JSON.parse(localStorage.getItem("prachQuesCount"));
        if (count) {
          localStorage.setItem("prachQuesCount", JSON.stringify(count + 1));
        } else {
          localStorage.setItem("prachQuesCount", 1);
        }
        let outputString = result.replace(/\n/g, " <br/>");
        // Define the regular expression to match the pattern
        var regex = /(<br\s*\/?>\s*)(\d+\.\s(?:.{0,97}?:))/gs;

        // Apply bold tag to numbers followed by dot adjacent after <br/> tag
        outputString = outputString.replace(regex, function(match, p1, p2) {
          // Check if the captured string after the number with a dot is longer than 100 characters
          // If it is, do not apply bold. This is a safeguard, though the regex already limits the capture.
          if (p2.length > 70) {
            return match;
          }
          // Return the line break as is, and wrap the number, dot, and following text up to the colon in <b> tags
          return p1 + "<b>" + p2 + "</b>";
        });
        let relatedResponse = null;
        try {
          relatedResponse = await axios.post(
            "https://www.pannalabs.ai:5001/generate-alternative-questions",
            {
              prompt: questionPromptTemp,
              rejectUnauthorized: false,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
        } catch (error) {
          console.log("error", error);
        }
        let relatedQuestionsArray = [];
        if (relatedResponse && relatedResponse.status === 200) {
          relatedQuestionsArray = relatedResponse?.data?.alternative_questions
            .split("\n")
            .filter((line) => line.trim() && /^\d+\./.test(line.trim()));
          console.log("relatedQues", relatedQuestionsArray);
        }

        let mssgObject = {};
        // mssgObject.id = uuidv4();
        mssgObject.question = questionPromptTemp;
        var urlRegex = /(?:<|\()?((https?:\/\/[^\s<>\)]+))(?:>|\))?/g;

        // Function to replace the matched URL and surrounding characters
        function replaceURLsAndSurroundings(match, url) {
          return `<a href="${url}" style="color: blue; text-decoration: underline;" target="_blank">${url}</a>`;
        }

        // Replace URLs in the text, handling surrounding characters
        var formattedText = outputString.replace(
          urlRegex,
          replaceURLsAndSurroundings
        );

        // Find the index of "<br/>References:" in the paragraph content
        var startIndex = formattedText.indexOf("<br/>References:");

        // If "<br/>References:" is found
        if (startIndex !== -1) {
          // Extract the content starting from "<br/>References:"
          var referencesContent = formattedText.substring(startIndex);

          // Replace the "<br/>References:" with an empty string to remove it
          referencesContent = referencesContent.replace("<br/>References:", "");

          // Split the references content by "<br/>* " to create an array of references
          var referencesArray = referencesContent.split("<br/>* ");

          // Remove the first empty string element created by the split
          referencesArray.shift();

          // Create a new list element to hold the references
          var listElement = document.createElement("ul");

          // Loop through each reference and create a list item for it
          referencesArray.forEach(function(reference) {
            // Create a list item element
            var listItem = document.createElement("li");

            // Set the inner HTML of the list item to the reference content
            listItem.innerHTML = reference;

            // Append the list item to the list element
            listElement.appendChild(listItem);
          });

          // Replace the original paragraph content with the formatted list
          formattedText =
            formattedText.substring(0, startIndex) + listElement.outerHTML;
        }

        mssgObject.answer = formattedText;
        mssgObject.id = response.data.result.id;
        // const answerSubString = outputString.substring((0, 1000));
        // const lastSpaceIndex = answerSubString.lastIndexOf(" ");
        mssgObject.viewed = 1000;
        mssgObject.rating = 0;
        mssgObject.feedback = "";
        mssgObject.relatedQuestions = relatedQuestionsArray;
        setChatResponse([...chatResponse, mssgObject]);
        chatHistory.unshift(mssgObject);
        setChatHistory(chatHistory);
        setEnteredPrompt("");
        setLoading(false);
        setCloseModal(true);
        setIsOpenChatAccordion(true);
        // navigate("/results");
      }
    } catch (error) {
      setLoading(false);
    }
  }
};

export const getVideoId = (url) => {
  console.log(url);
  if (url !== null) {
    let VID_REGEX =
      // /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
      // /(?:https?:\/\/)?(?:www\.)?youtube\.com\/(?:watch\?v=|shorts\/)([a-zA-Z0-9_-]+)/;
      /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=|shorts\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    // return item.video_url.match(VID_REGEX)[1];
    return url?.match(VID_REGEX)[1];
  }
  return "";
};
