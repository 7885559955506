import React from "react";
import { Link } from "@nextui-org/react";

const ContactUs = () => {
  return (
    <div className="flex h-[70vh] px-[10%] items-center justify-center">
      <div className="text-center flex flex-col gap-5 redHatBold">
        <div>
          We value your feedback and inquiries. Whether you have a question
          about our products, need assistance with an order, or just want to
          share your thoughts, we're here to help.
        </div>
        <div>
          Our dedicated team is committed to providing you with the best
          possible service and will respond to your message as quickly as
          possible.
        </div>
        <div>
          Please feel free to reach out to us via email at{" "}
          <Link href="mailto:prachaibot@gmail.com">prachaibot@gmail.com</Link>.
        </div>
        <div>
          We look forward to hearing from you and assisting you with all your
          needs.
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
