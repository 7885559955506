import React from "react";
import { Card, CardBody, Image } from "@nextui-org/react";
import { cn } from "../utils/cn";
import cardSearchArrow from "../images/backward-arrow-cards.svg";
import loginImg from "../images/login.svg";
import { FaUserAlt } from "react-icons/fa";
import { CgQuote } from "react-icons/cg";
import YouTube from 'react-youtube';

const Accolades = ({ className, userName, review, location, iconSrc, videoLinkId, setSliderOn }) => {
  const opts = {
    width: '298',
    height: '238',
    playerVars: {
      autoplay: 0,
    },
  };

  return (
    <button className={cn("flex-1", className)} name={userName}>
      <Card className={`h-full flex-row min-h-[80px] cursor-pointer w-[420px] rounded-[14px] ${videoLinkId ? 'p-0' : 'p-5' } border border-[#DDDDDD]`}>
        <div className={`flex flex-col ${videoLinkId ? 'p-5' : 'p-0' } pr-2 w-[120px]`}>
          <span>
            {iconSrc ? (
              <img
                src={iconSrc}
                alt={`${userName}-profile`}
                className="h-[100px] w-[100px] rounded-full"
              />
            ) : (
              <FaUserAlt color={"#ccc"} size={"100px"} />
            )}
            {/* <CgQuote
              size={"50px"}
              color={"#1859c9"}
              className="absolute top-[20px] left-[28%]"
            /> */}
          </span>
          <span className="flex flex-col mt-[12px]">
            <span className="redHatBold text-[16px] leading-[21px] pr-[5px]">
              {userName}
            </span>
            <span className="redHatBold text-[16px] leading-[21px] text-[#1859c9]">
              {/* {`  `} - {` `} */}
              {location}
            </span>
          </span>
        </div>
        <CardBody className="flex flex-row items-center justify-between gap-3 h-full w-[300px] p-0">
          <span className="flex items-center gap-2">
            <span className="flex flex-col gap-0">
            {videoLinkId ? 
              <YouTube videoId={videoLinkId} opts={opts} onPlay={() => {
                setSliderOn(false)
              }}
              onPause={() => {
                setSliderOn(true)
              }
              }
              onEnd={() => {
                setSliderOn(true)
              }
              } />
              :
              <span className="redHatMedim pt-[15px] font-semibold text-[14px] leading-[21px] text-[#505050]">
                {review}
              </span>
            }
            </span>
          </span>
        </CardBody>
      </Card>
    </button>
  );
};

export default Accolades;
