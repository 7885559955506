import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { NextUIProvider } from "@nextui-org/react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));

async function enableMocking() {
  if (process.env.NODE_ENV !== "development") {
    return;
  }

  const { worker } = await import("./mocks/browser");

  // return worker.start();
}

enableMocking().then(() => {
  root.render(
    <GoogleOAuthProvider clientId="308921932289-20n7laj11t16tjomhk655m6fki9um323.apps.googleusercontent.com">
      <NextUIProvider className="h-screen bg-[#f3f3f385]">
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </NextUIProvider>
    </GoogleOAuthProvider>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
