import React, {useState, useCallback} from "react";
import useEmblaCarousel from "embla-carousel-react";
import AutoScroll from "embla-carousel-auto-scroll";
import Accolades from "./Accolades";
import {getVideoId} from '../utils/utilityFunctions';

const EmblaCarousel = (props) => {
  const { testimonials } = props;
  const [sliderOn, setSliderOn] = useState(true);
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true }, [
    AutoScroll({ playOnInit: sliderOn, stopOnInteraction: false, speed: 2 }),
  ]);

  const toggleAutoplay = useCallback(() => {
    const autoScroll = emblaApi?.plugins()?.autoScroll
    if (!autoScroll) return
    const playOrStop = autoScroll.isPlaying()
      ? sliderOn
      : sliderOn
    playOrStop()
  }, [sliderOn])

  return (
    <div className="embla">
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {testimonials.map((item, index) => (
            <div className="embla__slide" key={index}>
              <div className="embla__slide__number">
                <Accolades
                  key={item.id}
                  userName={item.name}
                  review={item.review_text}
                  location={item.location}
                  iconSrc={item.profile_image_uri}
                  // videoLinkId={getVideoId(item.video_url)}
                  videoLinkId={item?.video_url ? getVideoId(item.video_url) : null}
                  setSliderOn={setSliderOn}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EmblaCarousel;
