import React, { useState, useCallback } from "react";
import useEmblaCarousel from "embla-carousel-react";
import AutoScroll from "embla-carousel-auto-scroll";
import YoutubeContent from "./YoutubeContent";
import { YoutubeVideos } from "../utils/videoData";
import {getVideoId} from '../utils/utilityFunctions';

const VideoSlider = () => {

    const [sliderOn, setSliderOn] = useState(true);
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true }, [
    AutoScroll({ playOnInit: sliderOn, stopOnInteraction: false, speed: 1 }),
  ]);

  const toggleAutoplay = useCallback(() => {
    const autoScroll = emblaApi?.plugins()?.autoScroll
    if (!autoScroll) return
    const playOrStop = autoScroll.isPlaying()
      ? sliderOn
      : sliderOn
    playOrStop()
  }, [sliderOn])

  return (
    <div className="embla">
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {YoutubeVideos.map((item, index) => (
            <div className="embla__slide" key={index}>
              <div className="embla__slide__number">
                <YoutubeContent
                  key={item.id}
                  videoLinkId={getVideoId(item.link)}
                  setSliderOn={setSliderOn}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VideoSlider;
