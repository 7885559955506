export let specialNeedsServices = [
  {
    id: 1,
    headline: "Find Inspiration (Case Studies)",
    body: "Discover success stories",
  },
  {
    id: 2,
    headline: "Discover Autism-Friendly Places",
    body: "Find parks & restaurants",
  },
  {
    id: 3,
    headline: "Explore Special Needs Topics",
    body: "Find articles & guides",
  },
  {
    id: 4,
    headline: "Stay Informed on Special Needs",
    body: "Access research",
  },
  {
    id: 5,
    headline: "Craft Your IEP",
    body: "Plan for success",
  },
  {
    id: 6,
    headline: "Ask About Special Needs",
    body: "Get expert answers",
  },
  {
    id: 7,
    headline: "Understand Insurance Coverage",
    body: "Learn about options",
  },
  {
    id: 8,
    headline: "Know Your Legal Rights",
    body: "Advocate for your child",
  },
  {
    id: 9,
    headline: "Explore Disability Laws",
    body: "Protect child's rights",
  },
  {
    id: 10,
    headline: "Find Exceptional Special Ed Schools",
    body: "Find perfect learning env.",
  },
  {
    id: 11,
    headline: "Explore Vocational Training Options",
    body: "Equip child for future",
  },
];
