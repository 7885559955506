import React from "react";
import { Link } from "@nextui-org/react";
import {
  facebookSvg,
  instagramSvg,
  linkedInSvg,
  whatsappSvg,
  youtubeSvg,
} from "../svgs/svgs";

const PrachCommunity = () => {
  return (
    <div className="flex h-[70vh] px-[10%] items-center justify-center">
      <div className="text-center">
        <div className="redHatBold">
          Stay connected with us through our social media platforms! Follow us
          to keep up-to-date with the latest news and behind-the-scenes content.
          Join our community and engage with us on:
        </div>
        <div className="flex gap-9 justify-center my-[20px]">
          <Link
            href="https://in.linkedin.com/company/prach-ai-autism"
            target="_blank"
          >
            {linkedInSvg({ height: "50px", width: "50px" })}
          </Link>
          <Link href="https://www.youtube.com/@PRACH-cj9lp" target="_blank">
            {youtubeSvg({ height: "50px", width: "50px" })}
          </Link>
          <Link
            href="https://whatsapp.com/channel/0029Vakrd3zAojYzJD9oeX0O"
            target="_blank"
          >
            {whatsappSvg({ height: "50px", width: "50px" })}
          </Link>
          <Link
            href="https://www.instagram.com/prachaibot?igsh=ZThjMDM3dTd2ZjM="
            target="_blank"
          >
            {instagramSvg({ height: "50px", width: "50px" })}
          </Link>
        </div>
        <div className="redHatBold">
          We love hearing from you, so don’t hesitate to like, comment, and
          share your experiences with our products and services. Your support
          and feedback are invaluable to us, and we look forward to connecting
          with you online!
        </div>
      </div>
    </div>
  );
};

export default PrachCommunity;
