import React, { useCallback, useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import AnswerComponent from "./AnswerComponent";
import logo from "../images/logo.svg";
import {
  Button,
  Divider,
  useDisclosure,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/react";
import ChatInput from "./ChatInput";
import Login from "./Login";
import Sidebar from "./Sidebar";
import loadingLogo from "../images/logo-without-name.svg";
import sharePng from "../images/sharePng.png";
import { isMobile, isTablet, isIPad13, isAndroid } from "react-device-detect";
import Feedback from "./Feedback";
import { FaRegShareFromSquare } from "react-icons/fa6";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { facebookSvg, shareSvg, twitterSvg, whatsappSvg } from "../svgs/svgs";
import { MdMoreVert } from "react-icons/md";
import {
  FacebookShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
// import { WhatsappShareButton } from "react-share";
// import whatsappIcon from "../images/whatsapp-icon.png";

const Results = () => {
  const context = useContext(AppContext);
  const {
    chatResponse,
    loading,
    setCurrentPage,
    checkLogin,
    setCheckLogin,
    signerData,
    isOpenSidebar,
    questionPrompt,
    setIsOpenSidebar,
    chatHistory,
  } = context;
  const { isOpen, onOpen, onClose, onOpenChange } = useDisclosure();

  useEffect(
    () => {
      setCurrentPage("results");
    },
    [setCurrentPage]
  );

  useEffect(() => {
    if (isMobile) {
      setIsOpenSidebar(false);
    }
  }, []);

  useEffect(
    () => {
      if (chatResponse.length > 0 && !loading) {
        let resp = chatResponse[chatResponse.length - 1];
        const div = document.getElementById(resp.id);
        div.scrollIntoView({ behavior: "smooth" });
      }
    },
    [chatResponse]
  );

  useEffect(
    () => {
      if (loading) {
        const div = document.getElementById("loader");
        div.scrollIntoView({ behavior: "smooth" });
      }
    },
    [loading]
  );

  useEffect(
    () => {
      if (
        JSON.parse(localStorage.getItem("prachQuesCount")) >= 0 &&
        Object.keys(signerData).length < 1
      ) {
        setCheckLogin(false);
        onOpen();
      }
    },
    [chatResponse, checkLogin]
  );

  const [upVote, setUpVote] = useState(false);
  const [downVote, setDownVote] = useState(false);

  const handleUpVoteClick = () => {
    setUpVote(!upVote);
    setDownVote(false);
  };

  const handleDownVoteClick = () => {
    setDownVote(!downVote);
    setUpVote(false);
  };

  const handleFeedbackModal = () => {};
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const [textCopied, setTextCopied] = useState("");
  const baseUrl = window.location.origin;

  const handleTextCopy = (id) => {
    setTextCopied(id);
    setTimeout(() => {
      setTextCopied("");
    }, 5000);
  };

  const trackEvent = useCallback(() => {
    // Check if gtag function is available
    if (window.gtag) {
      // Send an event to Google Analytics
      window.gtag("event", "copy_link", {
        event_category: "user_interaction",
        event_label: "link_copied",
      });
    }
  }, []);

  return (
    <div
      className={`${!isOpenSidebar ? "flex items-center justify-center" : ""}`}
    >
      {chatResponse?.length === 0 && (
        <div
          style={{
            position: "absolute",
            top: "45%",
            left: isOpenSidebar ? "60%" : "50%",
            textAlign: "center",
            transform: "translate(-50%, -50%)",
          }}
        >
          <div className="flex flex-col items-center justify-center opacity-[70%]">
            <img
              src={logo}
              alt="Prach Logo"
              className="w-[75px] h-[75px] pb-[10px]"
            />
            <span className="font-bold text-center redHatBold text-[14px] md:text-[16px]">
              Neurodiverse Companion
            </span>
          </div>
        </div>
      )}
      <div
        className={`mt-5 mb-20 ${
          isOpenSidebar && !isMobile
            ? "ml-[467px] pr-[250px] transition-margin duration-300"
            : "ml-0 transition-margin duration-400  w-[85%]"
        } relative `}
      >
        <Sidebar />
        {chatResponse.map((item, i) => {
          return (
            <div key={i}>
              <div
                id={item.id}
                className="redHatBold text-[16px] md:text-[22px] flex items-center"
              >
                {item.question}

                <CopyToClipboard
                  text={`${baseUrl}/results/${item.id}`}
                  onCopy={() => handleTextCopy(item.id)}
                >
                  <button
                    onClick={trackEvent}
                    className="cursor-pointer relative pl-2"
                  >
                    <img src={sharePng} alt="share icon" />
                    <span className="text-sm redHatRegular absolute top-[150%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-lg py-1 px-2">
                      {item.id === textCopied && "copied!"}
                    </span>
                  </button>
                </CopyToClipboard>
                <Dropdown className="w-fit min-w-fit">
                  <DropdownTrigger>
                    <Button className="h-fit min-w-fit p-0 bg-transparent">
                      <MdMoreVert className="text-[32px]" />
                    </Button>
                  </DropdownTrigger>
                  <DropdownMenu aria-label="share link">
                    <DropdownItem key="whatsapp">
                      <WhatsappShareButton
                        url={`${baseUrl}/results/${item.id}`}
                      >
                        {whatsappSvg({ height: "30px", width: "30px" })}
                      </WhatsappShareButton>
                    </DropdownItem>
                    <DropdownItem key="facebook">
                      <FacebookShareButton
                        url={`${baseUrl}/results/${item.id}`}
                      >
                        {facebookSvg({ height: "28px", width: "28px" })}
                      </FacebookShareButton>
                    </DropdownItem>
                    <DropdownItem key="twitter">
                      <TwitterShareButton url={`${baseUrl}/results/${item.id}`}>
                        {twitterSvg({ height: "28px", width: "28px" })}
                      </TwitterShareButton>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
              <div className="flex items-center mb-3">
                <span className="mr-2">
                  <img
                    src={loadingLogo}
                    alt="loader prach logo"
                    className="w-[18px] md:w-[29px] h-[16px] md:h-[23px]"
                  />
                </span>
                <div className="w-48 flex justify-between">
                  <span className="redHatMedium text-[14px] md:text-[16px] leading-[21px] text-[#6F6F6F]">
                    Answer:
                  </span>
                </div>
              </div>
              <div
                data-container="answer"
                className={`redHatRegular text-black text-[14px] md:text-[18px] leading-[18px] md:leading-[26px]`}
              >
                <AnswerComponent answer={item.answer} obj={item} />
              </div>
              <Divider className="mb-4 mt-[80px]" />
            </div>
          );
        })}
        {loading && (
          <div id="loader" className="flex flex-col gap-2 h-[80vh] mt-[20px]">
            <span className="redHatBold text-[16px] md:text-[22px]">
              {questionPrompt}
            </span>
            <div className="flex items-center redHatMedium text-[#6F6F6F] text-[16px] leading-[21px] gap-3">
              <img src={loadingLogo} alt="loader prach logo" />
              <span>Prach is getting answer</span>
              <div className="loader">
                <div className={`circle circle1`} />
                <div className={`circle circle2`} />
                <div className={`circle circle3`} />
                <div className={`circle circle4`} />
              </div>
            </div>
          </div>
        )}
        <ChatInput
          className={`fixed z-[100] bottom-[5%] md:bottom-[4%] ${
            isMobile || isTablet || isIPad13 || isAndroid
              ? "w-[94%]"
              : "w-[60%]"
          } ${
            isOpenSidebar && (!isMobile || !isTablet || !isIPad13 || !isAndroid)
              ? "left-[467px]"
              : "left-[3%]"
          }`}
        />
      </div>
    </div>
  );
};

export default Results;
