import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/react";
import { IoClose } from "react-icons/io5";
import { IoIosArrowDown } from "react-icons/io";

const WsDropdown = ({
  items,
  handleChange,
  selectedValue,
  title,
  handleCancel,
  subItem,
}) => {
  return (
    <div className="relative w-full">
      <Dropdown
        classNames={{
          trigger: ["rounded-[15px]", "py-[10px]", "px-[10px]", "w-full"],
        }}
      >
        <DropdownTrigger>
          <Button
            variant="bordered"
            className={`flex justify-between border-1 shadow-md ${
              selectedValue
                ? `font-bold ${
                    subItem
                      ? "border-pink-300 bg-pink-100"
                      : "border-green-300 bg-green-100"
                  }  `
                : ""
            } `}
          >
            <span className="max-w-[150px] overflow-hidden text-ellipsis whitespace-nowrap">
              {selectedValue || title}
            </span>
          </Button>
        </DropdownTrigger>
        <DropdownMenu
          aria-label="Dynamic Actions"
          items={items}
          onAction={handleChange}
          variant="faded"
        >
          {(item) => (
            <DropdownItem
              key={item.key}
              className={`${
                selectedValue === item.label
                  ? `${subItem ? "bg-pink-200" : "bg-green-200"}  redHatBold`
                  : "bg-inherit"
              }`}
            >
              {item.label}
            </DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>
      <div
        className="absolute right-0 top-[50%] z-10 cursor-pointer"
        style={{ transform: "translate(-50%, -50%)" }}
      >
        {selectedValue ? (
          <IoClose className="w-[15px]" onClick={handleCancel} />
        ) : (
          <IoIosArrowDown className="w-[15px]" />
        )}
      </div>
    </div>
  );
};

export default WsDropdown;
