import { createContext, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "./components/Header";
import Footer from "./components/Footer";
import HomePage from "./components/HomePage";
import Login from "./components/Login";
import Results from "./components/Results";
import History from "./components/History";
import AboutUs from "./components/AboutUs";
import Disclaimer from "./components/Disclaimer";
import PrivacyPolicy from "./components/PrivacyPolicy";
import {
  Modal,
  ModalBody,
  ModalContent,
  useDisclosure,
} from "@nextui-org/react";
import { sendLoginData } from "./components/services";
import MyStory from "./components/MyStory";
import ResultDetail from "./components/ResultDetail";
import ContactUs from "./components/ContactUs";
import PrachCommunity from "./components/PrachCommunity";
import axios from "axios";
import SuccessPage from "./components/SuccessPage";
import DonationForm from "./components/DonationForm";
import WorksheetDropdown from "./components/worksheets";

export const AppContext = createContext();
function App() {
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState("");
  const [enteredPrompt, setEnteredPrompt] = useState("");
  const [chatResponse, setChatResponse] = useState([]);
  const [loading, setLoading] = useState(false);
  const [signerData, setSignerData] = useState({});
  const [checkLogin, setCheckLogin] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [screenMargin, setScreenMargin] = useState({
    header: "60px",
    footer: "65px",
  });
  const [activeFaq, setActiveFaq] = useState("");
  const [isOpenSidebar, setIsOpenSidebar] = useState(true);
  const [isOpenFaqAccordion, setIsOpenFaqAccordion] = useState(false);
  const [isOpenChatAccordion, setIsOpenChatAccordion] = useState(true);
  const [questionPrompt, setQuestionPrompt] = useState("");
  const [loginPayload, setLoginPayload] = useState({});

  // worksheets
  const [fetchedCategories, setFetchedCategories] = useState([]);
  const [fetchResources, setFetchResources] = useState([]);
  const [worksheetPagesData, setWorksheetPagesData] = useState({
    page: 0,
    per_page: 0,
    total_items: 0,
    total_pages: 0,
  });
  const [selectedDropdowns, setSelectedDropdowns] = useState({});
  const [dropdownData, setDropdownData] = useState({});
  const [resources, setResources] = useState([]);
  const [currentWorksheetPage, setCurrentWorksheetPage] = useState(1);

  const [renderTestimonial, setRenderTestimonial] = useState(false);

  useEffect(() => {
    const header = document.getElementById("header");
    const footer = document.getElementById("footer");
    if (footer && header) {
      setScreenMargin({
        header: `${header.offsetHeight + 10}px`,
        footer: `${footer.offsetHeight + 10}px`,
      });
    }
  }, []);

  const { isOpen, onOpen, onClose, onOpenChange } = useDisclosure();

  useEffect(
    () => {
      if (
        JSON.parse(localStorage.getItem("prachQuesCount")) >= 1 &&
        Object.keys(signerData).length < 1 &&
        currentPage === "results"
      ) {
        setCheckLogin(false);
        onOpen();
      }
    },
    [chatResponse, checkLogin, currentPage]
  );

  useEffect(
    () => {
      if (Object.keys(signerData).length > 0) {
        navigate("/results");
      }
    },
    [signerData]
  );

  useEffect(
    () => {
      let {
        id,
        email,
        verified_email,
        name,
        given_name,
        family_name,
        picture,
        locale,
      } = signerData;
      let { deviceType, operatingSystem, browser, geolocation } = loginPayload;
      let loginObj = {
        user_id: id,
        email: email,
        email_verified: true ? "true" : "false",
        full_name: name,
        given_name: given_name,
        family_name: family_name,
        profile_picture_url: picture,
        locale: locale,
        login_method: "Google OAuth",
        device_type: deviceType,
        operating_system: operatingSystem,
        browser: browser,
        // ip_address: "900.118.2.1",
        geolocation: geolocation,
        // number_of_logins: 1,
        // failed_login_attempts: "",
        referral_source: "Direct",
      };
      const sendLoginDataObj = async () => {
        await sendLoginData(loginObj);
      };
      if (Object.keys(signerData).length > 0) {
        sendLoginDataObj();
      }
    },
    [loginPayload]
  );

  const schemaMarkup = {
    "@context": "http://schema.org",
    "@type": "WebSite",
    url: "https://www.pannalabs.ai/",
    name: "Prach AI - Autistic Assistant",
    description:
      "Prach: Your Autistic AI companion for kids, parents & caregivers. Prach uses play-based learning to support children on the Autism Spectrum, while offering resources and guidance for parents and caregivers.",
    publisher: {
      "@type": "Organization",
      name: "Prach",
      logo: {
        "@type": "ImageObject",
        url:
          "https://www.pannalabs.ai/static/media/logo.b30690c30ee0d03927b35bebe36fc8d7.svg",
      },
    },
    // You can add more properties here according to the schema.org documentation
  };

  // useEffect(() => {
  //   navigator.geolocation.getCurrentPosition(
  //     (position) => {
  //       console.log("Latitude is :", position.coords.latitude);
  //       console.log("Longitude is :", position.coords.longitude);
  //       axios
  //         .get(
  //           `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=YOUR_API_KEY`
  //         )
  //         .then((response) => {
  //           console.log(response.data);
  //         })
  //         .catch((error) => {
  //           console.log("Error", error);
  //         });
  //     },
  //     (error) => {
  //       console.log("Error occurred. Error code: " + error.code);
  //     },
  //     {
  //       enableHighAccuracy: true,
  //       timeout: 5000,
  //       maximumAge: 0,
  //     }
  //   );
  // }, []);

  return (
    <AppContext.Provider
      value={{
        currentPage,
        setCurrentPage,
        enteredPrompt,
        setEnteredPrompt,
        chatResponse,
        setChatResponse,
        loading,
        setLoading,
        signerData,
        setSignerData,
        checkLogin,
        setCheckLogin,
        screenMargin,
        setScreenMargin,
        chatHistory,
        setChatHistory,
        activeFaq,
        setActiveFaq,
        isOpenSidebar,
        setIsOpenSidebar,
        isOpenFaqAccordion,
        setIsOpenFaqAccordion,
        isOpenChatAccordion,
        setIsOpenChatAccordion,
        questionPrompt,
        setQuestionPrompt,
        loginPayload,
        setLoginPayload,
        fetchedCategories,
        setFetchedCategories,
        fetchResources,
        setFetchResources,
        worksheetPagesData,
        setWorksheetPagesData,
        selectedDropdowns,
        setSelectedDropdowns,
        dropdownData,
        setDropdownData,
        resources,
        setResources,
        currentWorksheetPage,
        setCurrentWorksheetPage,
        renderTestimonial,
        setRenderTestimonial,
      }}
    >
      <div className="flex flex-col min-h-screen relative bg-[#f3f3f385]">
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify(schemaMarkup)}
          </script>
        </Helmet>

        <Modal
          placement="center"
          backdrop="blur"
          size="2xl"
          hideCloseButton
          className="w-[90%] sm:w-fit rounded-[30px]"
          isOpen={isOpen}
          onOpenChange={onOpenChange}
          isDismissable={false}
          classNames={{
            backdrop: ["z-[150]"],
            wrapper: ["z-[150]", "modalStyle"],
          }}
        >
          <ModalContent>
            {(onClose) => (
              <>
                <ModalBody className="p-0">
                  <Login
                    onClose={onClose}
                    fromHomePage={true}
                    className={"rounded-[30px]"}
                  />
                </ModalBody>
              </>
            )}
          </ModalContent>
        </Modal>
        <Header
          className={`bg-[#F8F8F8] py-2 px-5 sm:px-10 lg:px-20 fixed w-full top-0 z-50`}
        />
        <Routes>
          <Route
            path="/"
            exact
            element={
              <HomePage
                className={`flex-1 overflow-y-auto px-5 sm:px-10 lg:px-20 pb-[82px] bg-[#F8F8F8]`}
              />
            }
          />
          <Route
            path="/login"
            exact
            element={
              <Login className="flex-1 overflow-y-auto my-20 px-5 sm:px-10 lg:px-20" />
            }
          />
          <Route path="/results" exact element={<Results />} />
          <Route path="/results/:id" exact element={<ResultDetail />} />
          <Route path="/history" exact element={<History />} />
          <Route path="/my-story" exact element={<MyStory />} />
          <Route path="/about-prach" exact element={<AboutUs />} />
          <Route path="/contact-prach" exact element={<ContactUs />} />
          <Route path="/prach-community" exact element={<PrachCommunity />} />
          <Route path="/disclaimer" exact element={<Disclaimer />} />
          <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
          {/* <Route
            path={["/donate", "/cancel"]}
            exact
            element={<DonationForm />}
          /> */}
          <Route path="/donate" exact element={<DonationForm />} />
          <Route path="/cancel" exact element={<DonationForm />} />
          <Route path="/success" exact element={<SuccessPage />} />
          <Route path="/worksheets" exact element={<WorksheetDropdown />} />
        </Routes>

        <Footer className="bg-[#EFEFEF] py-2 px-5 sm:px-10 lg:px-20 fixed w-full bottom-0 z-[55]" />
      </div>
      <iframe
        // src="http://localhost:3001/?dc_language=ES"
        src="https://prachbot.pannalabs.ai"
        title="chatbot"
        id="PannaLabs-chat"
        name="PannaLabs-chat"
        style={{
          overflow: "hidden",
          minHeight: "180px",
          minWidth: "200px",
          position: "fixed",
          bottom: "10px",
          display: "inline",
        }}
      />
    </AppContext.Provider>
  );
}

export default App;
