import React from "react";
import { Card, Image } from "@nextui-org/react";
// import Markdown from "react-markdown";
// import rehypeRaw from "rehype-raw";

const SpecialNeedsServiceCard = ({ id, headline, body }) => {
  return (
    <Card className="rounded-full shadow-md p-4 flex items-center bg-[#e3e3e380] min-w-[200px]">
      {/* <Markdown rehypePlugins={[rehypeRaw]}>{icon}</Markdown> */}
      <div className="flex flex-col h-full justify-center items-center">
        <span h4 className="font-bold text-[14px]">
          {headline}
        </span>
        <span className="text-gray-600 text-[12px]">{body}</span>
      </div>
    </Card>
  );
};

export default SpecialNeedsServiceCard;
