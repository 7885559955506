import { Button, Card, CardBody, Image } from "@nextui-org/react";
import { FaCloudDownloadAlt } from "react-icons/fa";

const WorksheetCard = ({
  image,
  title,
  summary,
  price,
  keywords,
  worksheetLink,
}) => {
  const handleDownload = () => {
    window.open(worksheetLink, "_blank");
  };
  return (
    // <Card
    //   isBlurred
    //   className="rounded-xl border-none bg-background/60"
    //   shadow="md"
    //   style={{ cursor: "pointer" }}
    // >
    //   <CardBody>
    //     <div className="grid grid-cols-6 lg:grid-cols-12 gap-6 md:gap-4 items-center justify-center">
    //       <div className="relative col-span-6 md:col-span-6">
    //         <Image
    //           alt="Resource image"
    //           className="object-cover"
    //           height={200}
    //           shadow="md"
    //           src={image}
    //           width="100%"
    //         />
    //       </div>

    //       <div className="flex flex-col col-span-6 md:col-span-8">
    //         <div className="flex justify-between items-start">
    //           <div className="flex flex-col gap-0">
    //             <h3 className="text-large font-bold text-foreground/90">
    //               {title}
    //             </h3>
    //             <p className="text-medium text-foreground/80">{summary}</p>
    //             <h1 className="text-small font-semibold mt-2">
    //               Tags: {keywords.join(", ")}
    //             </h1>
    //           </div>
    //         </div>
    //         <div className="flex mt-2 justify-between items-center gap-2">
    //           <h3>Price: {price} INR</h3>
    //           <Button
    //             isIconOnly
    //             className="bg-gray-800 text-white"
    //             onClick={handleDownload}
    //           >
    //             <FaCloudDownloadAlt size={"25px"} />
    //           </Button>
    //         </div>
    //       </div>
    //     </div>
    //   </CardBody>
    // </Card>

    <Card
      isBlurred
      className="rounded-xl border-none bg-background/60 shadow-md mx-auto"
      style={{ cursor: "pointer", maxWidth: "800px" }} // Added max-width for larger screens
    >
      <CardBody>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 items-center">
          <div className="relative">
            <Image
              alt="Resource image"
              className="object-cover rounded-md"
              height={240}
              width="100%"
              shadow="md"
              src={image}
            />
          </div>

          <div className="flex flex-col gap-2">
            <h3 className="text-lg font-bold text-foreground/90">{title}</h3>
            <p className="text-sm text-foreground/70">{summary}</p>

            <div className="mt-1">
              <h1 className="text-sm font-semibold">
                Tags:{" "}
                <span className="text-foreground/80">
                  {keywords.join(", ")}
                </span>
              </h1>
            </div>

            <div className="flex justify-between items-center mt-4">
              <h3 className="text-md font-semibold">Price: {price} INR</h3>
              <Button
                isIconOnly
                className="bg-gray-800 text-white hover:bg-gray-700 p-2 rounded-full"
                onClick={handleDownload}
              >
                <FaCloudDownloadAlt size={"20px"} />
              </Button>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default WorksheetCard;
