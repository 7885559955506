import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import FilterButton from "../FilterButton";
import { applyFilter } from "./CustomDropdown";
import { useContext, useState } from "react";
import { AppContext } from "../../App";

export default function WsModal({ isOpen, onOpenChange, children }) {
  const {
    selectedDropdowns,
    worksheetPagesData,
    setFetchResources,
    fetchedCategories,
    setSelectedDropdowns,
    setDropdownData,
    setWorksheetPagesData,
    setCurrentWorksheetPage,
  } = useContext(AppContext);

  const [loading, setLoading] = useState(false);

  const handleFilter = async () => {
    let result = await applyFilter(
      setFetchResources,
      setWorksheetPagesData,
      selectedDropdowns,
      setLoading,
      fetchedCategories,
      setSelectedDropdowns,
      setDropdownData,
      true
    );
    setCurrentWorksheetPage(1);
    return result;
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        placement="center"
        scrollBehavior="inside"
      >
        <ModalContent className="pb-1">
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Search Wokrsheets
              </ModalHeader>
              <ModalBody className="p-[5px]">{children}</ModalBody>
              <ModalFooter>
                <FilterButton
                  worksheetPagesData={worksheetPagesData}
                  handleFilter={handleFilter}
                  onClose={onClose}
                  loading={loading}
                />
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
