import React, { useContext, useMemo } from "react";
import { AppContext } from "../../../App";
import FilterButton from "../../FilterButton";
import worksheetServices from "../services";
import WsDropdown from "./WsDropdown";
import { Divider } from "@nextui-org/react";

export const prepareTags = (selectedDropdowns) => {
  const tags = [];
  // Loop through each item in the state and add selected values to the tags
  // eslint-disable-next-line no-unused-vars
  for (const [key, value] of Object.entries(selectedDropdowns)) {
    if (value.primary && value.primary.toLowerCase() !== "all levels") {
      tags.push(value.primary.toLowerCase());
    }
    if (value.secondary && value.secondary.toLowerCase() !== "all levels") {
      tags.push(value.secondary.toLowerCase());
    }
  }

  return { tags };
};

const setCategories = (
  fetchedCategories,
  setSelectedDropdowns,
  setDropdownData
) => {
  const dropdownState = {};
  const structuredData = {};
  if (fetchedCategories.length > 0) {
    fetchedCategories.forEach((category) => {
      const categoryKey = category.category_name
        .replace(/\s+/g, "_")
        .toLowerCase(); // Normalize category name as key
      dropdownState[categoryKey] = { primary: "", secondary: "All levels" };

      structuredData[categoryKey] = {
        label: category.category_name,
        subItems: category.subcategories.map((sub) => ({
          key: sub.subcategory_name,
          label: sub.subcategory_name,
          subItems:
            sub.subchildren?.map((subchild) => ({
              key: subchild.subchild_name,
              label: subchild.subchild_name,
            })) || [],
        })),
      };
    });

    setSelectedDropdowns(dropdownState);
    setDropdownData(structuredData);
  }
};

export const applyFilter = async (
  setFetchResources,
  setWorksheetPagesData,
  selectedDropdowns,
  setLoading,
  fetchedCategories,
  setSelectedDropdowns,
  setDropdownData,
  isMobile
) => {
  const getResources = async (_page = 1, perPage = 10) => {
    try {
      setLoading(true);
      const response = await worksheetServices.getAllResources(_page, perPage);
      if (response) {
        const { items, page, per_page, total_items, total_pages } = response;
        setFetchResources(items || []);
        setWorksheetPagesData({
          page,
          per_page,
          total_items,
          total_pages,
        });
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching resources:", error);
    }
  };
  try {
    setLoading(true);
    if (prepareTags(selectedDropdowns).tags.length > 0) {
      let response = await worksheetServices.getResourceByCategory(
        prepareTags(selectedDropdowns).tags
      );
      if (response.items.length > 0) {
        const { items, page, per_page, total_items, total_pages } = response;
        setFetchResources(items || []);
        setWorksheetPagesData({
          page,
          per_page,
          total_items,
          total_pages,
        });
      }
      setLoading(false);
      setCategories(fetchedCategories, setSelectedDropdowns, setDropdownData);
    } else {
      getResources();
    }
  } catch (error) {
    console.log("error", error.message);
  } finally {
    return isMobile;
  }
};

const Index = ({ setLoading, ...props }) => {
  const {
    selectedDropdowns,
    setSelectedDropdowns,
    dropdownData,
    setDropdownData,
    worksheetPagesData,
    fetchedCategories,
    setFetchResources,
    setWorksheetPagesData,
    setCurrentWorksheetPage,
  } = useContext(AppContext);

  useMemo(
    () => {
      setCategories(fetchedCategories, setSelectedDropdowns, setDropdownData);
    },
    [fetchedCategories]
  );

  const subItemFilters = useMemo(
    () => {
      let filters = {};
      Object.keys(selectedDropdowns).forEach((key) => {
        filters[key] = dropdownData[key]?.subItems.find(
          (item) => item.label === selectedDropdowns[key].primary
        );
      });
      return filters;
    },
    [selectedDropdowns, dropdownData]
  );

  const handleDropdownChange = (key, value, isSecondary = false) => {
    setSelectedDropdowns((prev) => {
      const updatedDropdowns = {
        ...prev,
        [key]: {
          ...prev[key],
          [isSecondary ? "secondary" : "primary"]: value,
        },
      };

      // If the primary dropdown is updated, validate the secondary dropdown
      if (!isSecondary) {
        const selectedSubItem = dropdownData[key]?.subItems.find(
          (item) => item.label === value
        );
        const validSecondary =
          selectedSubItem?.subItems?.some(
            (subItem) => subItem.label === updatedDropdowns[key].secondary
          ) || false;

        // Reset the secondary dropdown if the selected value is no longer valid
        if (!validSecondary) {
          updatedDropdowns[key].secondary = "";
        }
      }

      return updatedDropdowns;
    });
  };

  const handleFilter = async () => {
    await applyFilter(
      setFetchResources,
      setWorksheetPagesData,
      selectedDropdowns,
      setLoading,
      fetchedCategories,
      setSelectedDropdowns,
      setDropdownData,
      false
    );
    setCurrentWorksheetPage(1);
  };

  return (
    <main className={props.className}>
      {/* <div className="p-1 slider-container">*/}
      <div className="p-1 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
        {Object.keys(selectedDropdowns).length > 0 &&
          Object.keys(dropdownData).map((key, i) => (
            <WsDropdown
              key={key + i}
              items={dropdownData[key]?.subItems}
              handleChange={(value) => handleDropdownChange(key, value)}
              selectedValue={selectedDropdowns[key]?.primary}
              title={dropdownData[key]?.label}
              handleCancel={() => handleDropdownChange(key, "", false)}
            />
          ))}
      </div>
      <Divider className="my-2 sm:my-4" />
      {/* <div className="flex items-end mt-4 p-1 slider-container"> */}
      <div className="p-1 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
        {Object.keys(subItemFilters).map(
          (key) =>
            selectedDropdowns[key]?.primary &&
            subItemFilters[key]?.subItems?.length > 0 && (
              <div key={key} className="flex flex-col">
                <div className="redHatSemiBold">{`Select ${
                  selectedDropdowns[key]?.primary
                }:`}</div>
                <WsDropdown
                  items={subItemFilters[key]?.subItems}
                  handleChange={(value) =>
                    handleDropdownChange(key, value, true)
                  }
                  selectedValue={selectedDropdowns[key]?.secondary}
                  title={selectedDropdowns[key]?.secondary || "All"}
                  handleCancel={() => handleDropdownChange(key, "", true)}
                  subItem
                />
              </div>
            )
        )}
      </div>

      {/* <div className="mt-3 flex  justify-between items-center">
        <span>{worksheetPagesData?.total_items} results</span>
        <Button
          className="font-bold bg-blue-200 border-1 border-blue-500"
          startContent={<FiFilter />}
          onClick={applyFilter}
        >
          Apply Filter
        </Button>
      </div> */}
      <FilterButton
        className="hidden sm:flex"
        worksheetPagesData={worksheetPagesData}
        handleFilter={handleFilter}
      />
    </main>
  );
};

export default Index;
