import { Button } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import { HiBadgeCheck } from "react-icons/hi";
import { useEffect, useState } from "react";

const SuccessPage = () => {
  const navigate = useNavigate();
  const [mode, setMode] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const query = queryParams.get("mode");
    setMode(query);
  }, []);

  return (
    <div className="bg-gray-100 min-h-screen flex flex-col justify-center items-center p-5 text-center">
      <h1 className="text-6xl font-bold mb-6 redHatBold ">Thank You! 😊</h1>
      {mode === "subscription" ? (
        <p className="text-lg mb-6 redHatSemiBold">
          <span className="flex">
            <span>Your Subscription was successful </span>
            <HiBadgeCheck size="25px" color="green" />.
          </span>
        </p>
      ) : (
        <>
          <p className="text-lg mb-4 redHatSemiBold">
            <span className="flex">
              <span>Your donation was successful </span>
              <HiBadgeCheck size="25px" color="green" />.
            </span>
          </p>
          <p className="text-lg mb-4 redHatSemiBold">
            Thank you for your generosity!
          </p>
          <p className="text-lg mb-6 redHatSemiBold">
            Your support will make a real difference in the lives of those in
            the Prach community.
          </p>
        </>
      )}
      <Button
        onClick={() => navigate("/")}
        className="bg-black text-white text-[16px] font-bold py-2 px-4 transition duration-300 ease-in-out transform hover:scale-105"
      >
        Return to Home Page
      </Button>
    </div>
  );
};

export default SuccessPage;
