import React from "react";
import loadingLogo from "../../images/logo-without-name.svg";

const Loader = () => {
  return (
    <div
      id="loader"
      className="absolute top-[50%] left-[50%]"
      style={{ transform: "translate(-50%, -50%)" }}
    >
      <div className="flex items-center redHatMedium text-[#6F6F6F] text-[16px] leading-[21px] gap-3">
        <img src={loadingLogo} alt="loader prach logo" />
        <span>Prach is loading worksheets</span>
        <div className="loader">
          <div className={`circle circle1`} />
          <div className={`circle circle2`} />
          <div className={`circle circle3`} />
          <div className={`circle circle4`} />
        </div>
      </div>
    </div>
  );
};

export default Loader;
