export let YoutubeVideos = [
    {
      id: 1,
      link: "https://youtu.be/aDFPP7TkX08",
    },
    {
      id: 2,
      link: "https://youtu.be/xdSmdKz5TUU",
    },
    {
      id: 3,
      link: "https://youtu.be/GcRadPEUDu0",
    },
    {
      id: 4,
      link: "https://youtu.be/Qw1E96ZjQqM",
    },
    {
      id: 5,
      link: "https://youtu.be/eAvl0aJglB8",
    },
    {
      id: 6,
      link: "https://youtu.be/PEtvN07lOGM",
    },
    {
      id: 7,
      link: "https://youtu.be/_FwJ4BPZzCo",
    },
  ];
  