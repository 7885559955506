import { Pagination } from "@nextui-org/react";
import React, { useContext, useEffect, useMemo, useState } from "react";
import WorksheetCard from "./WorksheetCard"; // Your card component
import worksheetServices from "../services";
import { AppContext } from "../../../App";
import Loader from "../Loader";

const Index = () => {
  const {
    resources,
    setResources,
    currentWorksheetPage,
    setCurrentWorksheetPage,
    fetchResources: worksheets,
    worksheetPagesData,
  } = useContext(AppContext);
  const perPage = 10;

  const [loading, setLoading] = useState(false);

  const getFetchResources = async (page) => {
    setLoading(true);
    try {
      const data = await worksheetServices.getAllResources(page, perPage);
      setResources(data.items);
    } catch (error) {
      console.error("Error fetching resources:", error);
    } finally {
      setLoading(false);
    }
  };
  const getWorksheets = useMemo(
    () => {
      if (resources.length > 0) {
        return resources;
      }
      return worksheets;
    },
    [resources, worksheets]
  );

  const handleChange = (page) => {
    setCurrentWorksheetPage(page);
    getFetchResources(page);
  };
  return (
    <div className="mt-5 p-1">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3  gap-5">
        {loading ? (
          <Loader />
        ) : (
          getWorksheets?.map((worksheet, i) => {
            const {
              image_link,
              resource_details: { title, description, price },
              tags,
              resource_link,
            } = worksheet;

            return (
              <WorksheetCard
                key={worksheet.resource_id + i}
                image={image_link}
                // image={
                //   "https://15worksheets.com/wp-content/uploads/2023/02/1-3.png"
                // }
                title={title}
                summary={description}
                price={price}
                keywords={tags}
                worksheetLink={resource_link}
              />
            );
          })
        )}
      </div>
      {/* Pagination Component */}
      {!loading && (
        <div className="mt-4 flex justify-center">
          <Pagination
            total={worksheetPagesData.total_pages}
            initialPage={1}
            page={currentWorksheetPage}
            // onChange={(page) => setCurrentWorksheetPage(page)}
            onChange={handleChange}
          />
        </div>
      )}
    </div>
  );
};

export default Index;
